<template>

<app-page class="home">

	<div class="text">

		<div class="text-inner" v-html="page.text" />

	</div>

	<div class="home-foot">

		<div class="home-foot-inner" v-html="home.foot" />

	</div>

</app-page>

</template>

<script>

export default {

	computed: {

		home: function() {

			return this.$store.getters['data'].home

		},

		page: function() {

			return this.$_.findWhere(this.$store.getters['data'].links, {
				slug: this.$route.params.slug
			})

		}

	}

}

</script>

<style scoped>

.text {
	width: 100%;
	padding: 80px 10px 40px 10px;
}

.text-inner {
	margin: 0px auto;
	max-width: 960px;
}

.is-mobile .text {
	padding: 60px 10px 20px 10px;
}

.text >>> p {
	margin-bottom: 20px;
	line-height: 22px;
	font-size: 18px;
}

.text >>> a {
	color: #5548AF;
}

.text >>> a:hover {
	text-decoration: underline;
}

.text >>> ul {
	margin-bottom: 20px;
	list-style: disc;
	list-style-position: outside;
	margin-left: 16px;
}

.text >>> ol {
	margin-bottom: 20px;
	list-style: decimal;
	list-style-position: outside;
	margin-left: 16px;
}

.text >>> li {
	line-height: 22px;
	font-size: 18px;
	padding-left: 10px;
}

.home-foot {
	background-color: #343434;
	color: #fff;
	padding: 40px 0px;
}

.is-mobile .home-foot {
	padding: 20px 10px;
	width:100%;
}

.home-foot >>> p {
	margin-bottom: 10px;
	font-weight: 300;
	line-height: 16px;
	font-size: 13px;
}

.home-foot >>> a {
	text-decoration: underline;
}

.home-foot >>> hr {
	height: 1px;
	border: 0px;
	margin: 20px 0px;
	border-top: 1px solid #fff;
}

.home-foot-inner {
	margin: 0px auto;
	max-width: 960px;
}

</style>
